<template>
	<div class="delivery-map">
		<div
			class="map"
			:id="mapid"
		>
			<div class="map__address-wrapper">
				<div
					class="map__address"
					v-if="deliveryAddress"
				>
					<!--<div class="map__address-title">Ваш адрес</div>-->
					<div class="map__address-text">{{ deliveryAddress }}</div>
				</div>
			</div>
			<div
				:class="isAvailable !== 'no-rests' ? 'map__icon' : 'map__icon-disabled'"
				v-if="!hidePlacemark"
			>
				<span class="map__icon-text">Вне зоны доставки</span>
			</div>
			<transition>
				<div
					class="map__popup restaurant"
					v-if="popupStatus"
				>
					<div
						class="restaurant__close-btn"
						@click="closePopup"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								width="24"
								height="24"
								fill="white"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13.4141 12L17.7071 7.70701C18.0981 7.31601 18.0981 6.68401 17.7071 6.29301C17.3161 5.90201 16.6841 5.90201 16.2931 6.29301L12.0001 10.586L7.70713 6.29301C7.31613 5.90201 6.68413 5.90201 6.29313 6.29301C5.90213 6.68401 5.90213 7.31601 6.29313 7.70701L10.5861 12L6.29313 16.293C5.90213 16.684 5.90213 17.316 6.29313 17.707C6.48813 17.902 6.74413 18 7.00013 18C7.25613 18 7.51213 17.902 7.70713 17.707L12.0001 13.414L16.2931 17.707C16.4881 17.902 16.7441 18 17.0001 18C17.2561 18 17.5121 17.902 17.7071 17.707C18.0981 17.316 18.0981 16.684 17.7071 16.293L13.4141 12Z"
								fill="#8B9092"
							/>
						</svg>
					</div>
					<div class="restaurant__zone">
						Территория охвата: <span class="zone-label">{{ orderData.zone }}</span>
					</div>
					<div class="restaurant__address">
						<div class="address-label">
							{{ orderData.address }}
						</div>
					</div>
					<div class="restaurant__orders-count">
						<div class="order-count__label">
							Всего заказов:
							<span class="order-count__value">
                {{ orderData.count }}
              </span>
						</div>
					</div>
					<div class="restaurant__filters">
						<div class="filters__data-picker">
							<div class="data-picker__input">
								<input
									type="text"
									@click="pickerStatus = true"
									:readonly="true"
									:value="formatedRange"
								>
							</div>
							<VueDatepicker
								class="data-picker"
								v-click-outside="closePicker"
								v-if="pickerStatus"
								ref="datepicker"
								is-range
								weekdays
								color="green"
								:masks="masks"
								:columns="2"
								:value="dates"
								trim-weeks
								@input="updateDates"
							/>
						</div>
						<div class="filters__sort">
							<div class="sort__label">
								Сортировать: <span
								class="sort-selected"
								@click="sortInputActive = !sortInputActive"
								:class="{'sort-selected__active': sortInputActive}"
							>{{
									activeFilterName
								}}</span>
							</div>
							<div
								class="sort-options"
								v-if="sortInputActive"
								v-click-outside="closeSort"
							>
								<div
									class="by-jure option-label"
									:class="{'option-label__active': sort === 'type'}"
									@click="sort = 'type';closeSort()"
								>
									По типу покупателя
								</div>
								<div
									class="by-sum option-label"
									:class="{'option-label__active': sort === 'summ'}"
									@click="sort = 'summ';closeSort()"
								>
									По сумме заказа
								</div>
								<div
									class="by-count option-label"
									:class="{'option-label__active': sort === 'orders'}"
									@click="sort = 'orders';closeSort()"
								>
									По кол-ву заказов
								</div>
								<hr>
								<div
									class="by-increase option-label"
									:class="{'option-label__active': sortOrder === 'increase'}"
									@click="sortOrder = 'increase';closeSort()"
								>
									По возрастанию
								</div>
								<div
									class="by-decrease option-label"
									:class="{'option-label__active': sortOrder === 'decrease'}"
									@click="sortOrder = 'decrease';closeSort()"
								>
									По убыванию
								</div>
							</div>
						</div>
					</div>
					<div class="users-list">
						<div class="name-label row-name-label">
							ФИО клиента
						</div>
						<div class="phone-label row-name-label">
							Телефон
						</div>
						<div class="order-count-label row-name-label">
							Заказы
						</div>
						<div class="order-count-label row-name-label">
							Сумма
						</div>
					</div>
					<div class="users-list__row">
						<div
							class="row-item-wr"
							v-for="(user, i) in sortedOrders"
							:key="user.id"
							@click="getUserData(user, i)"
						>
							<div
								class="row-item"
								:style="{backgroundColor: (user.expandedContent.expanded && user.expandedContent) ? '#F0F8F0' : '#FFF'}"
							>
								<div class="user__name">
									<div
										class="user__fio-label"
										v-if="user.name"
									>
										<div
											class="arrow_icon"
											:class="{'arrowUp' : user.expandedContent.expanded, 'arrowDown' : !user.expandedContent.expanded}"
										>
										</div>
										{{ user.name }}
									</div>
									<div
										class="user__type phys-user-type"
										v-if="user.type === 'phisical'"
									>
										Физ. лицо
									</div>
									<div
										class="user__type juridical-user-type"
										v-else
									>
										Юр. лицо
									</div>
								</div>
								<div class="user__phone">
									{{ user.phone }}
								</div>
								<div class="user__order-count">
									{{ user.count }}
								</div>
								<div class="user__order-count">
									{{ user.summ }}
								</div>
							</div>
							<div
								class="row-item__expanded"
								v-if="user.expandedContent.expanded && user.expandedContent"
							>
								<div class="expanded__row">
									<div class="row__head">
										<div class="row__date row-name-label">
											Дата
										</div>
										<div class="row__time row-name-label">
											Время
										</div>
										<div class="row__summ row-name-label">
											Сумма
										</div>
									</div>
									<div
										class="row__value"
										v-for="(userItem, i) in user.orders"
										:key="i"
									>
										<div class="row__date-value row-value-label">
											{{ userItem.date }}
										</div>
										<div class="row__time-value row-value-label">
											{{ userItem.time }}
										</div>
										<div class="row__summ-value row-value-label">
											{{ userItem.summ }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<div class="map__controls">
				<div class="map__controls-zoom">

					<button
						class="map__controls-zoom-in"
						@click="changeZoom(1)"
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
								fill="#40AE49"
							/>
						</svg>
					</button>

					<button
						class="map__controls-zoom-out"
						@click="changeZoom(-1)"
					>
						<svg
							width="14"
							height="2"
							viewBox="0 0 14 2"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M14 2H0V0H14V2Z"
								fill="#40AE49"
							/>
						</svg>
					</button>

				</div>
				<button
					class="map__controls-geolocation"
					@click="geolocateUser"
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.849 0.064325L0.517973 7.56339C-0.252985 7.87878 -0.135148 9.00535 0.684398 9.15436L9.28238 10.7176L10.8457 19.3156C10.9947 20.1352 12.1212 20.253 12.4366 19.4821L19.9357 1.15103C20.2154 0.467204 19.5328 -0.215445 18.849 0.064325ZM11.9747 16.2098L10.8188 9.85197C10.7567 9.51053 10.4895 9.24329 10.148 9.18123L3.79014 8.02524L17.641 2.359L11.9747 16.2098Z"
							fill="#40AE49"
						/>
					</svg>
				</button>
			</div>
		</div>
		<block-preloader v-if="$store.state.map.isLoading"/>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {loadYmap} from "vue-yandex-maps";
import search from "@/mixins/search";
import BlockPreloader from "../../ui/BlockPreloader.vue";

export default {
	name: "DeliveryMap",
	props: ["mapid", "isAvailable", "hidePlacemark", "showMarkerPopup"],
	components: {BlockPreloader},
	mixins: [search],

	computed: {
		...mapState(["currentCityRestIsWork"]), ...mapState("map", ["dates"]), ...mapGetters({
			openPopupName: "getOpenPopupName",
			notificationType: "getMapPopupNotificationType",
			deliveryAddress: "map/getDeliveryAddress",
			needMapUpdate: "map/needMapUpdate",
			cities: "getCities",
			currentCity: "getCurrentCity",
		}),

		formatedRange()
		{
			// formating date range 18 апр - 20 апр.2022
			let start = this.dates.start;
			let end = this.dates.end;
			let startMonth = start.toLocaleString("ru", {month: "short"});
			let endMonth = end.toLocaleString("ru", {month: "short"});
			let startDay = start.getDate();
			let endDay = end.getDate();
			let year = end.getFullYear();
			return `${startDay} ${startMonth} - ${endDay} ${endMonth}${year}`;
		},

		orderData()
		{
			return this.$store.state.map.ordersData;
		},

		sortedOrders: function ()
		{
			let sort = this.sort;
			let users = this.users;
			let sortOrder = this.sortOrder;
			let sortProp = "";

			if (sort === "orders")
				sortProp = "count";
			if (sort === "summ")
				sortProp = "summ";
			if (sort === "type")
				sortProp = "type";

			//сортировка по возрастанию
			if (sortOrder === "increase")
			{
				users.sort((a, b) =>
				{
					if (a[sortProp] > b[sortProp])
						return 1;
					if (a[sortProp] < b[sortProp])
						return -1;
					return 0;
				});
				users = users.sort((a, b) => a[sortProp] - b[sortProp]);
			}
			//сортировка по убыванию
			if (sortOrder === "decrease")
			{
				users.sort((a, b) =>
				{
					if (a[sortProp] < b[sortProp])
						return 1;
					if (a[sortProp] > b[sortProp])
						return -1;
					return 0;
				});
				users = users.sort((a, b) => b[sortProp] - a[sortProp]);
			}
			return users;
		},
		// возвращать имя активного фильтра для отображения в попапе
		activeFilterName()
		{
			let filterValue = this.sort;
			let filterName = "";
			if (filterValue === "orders")
			{
				filterName = "Количество заказов";
			} else if (filterValue === "summ")
			{
				filterName = "Сумма заказов";
			} else if (filterValue === "type")
			{
				filterName = "По типу покупателя";
			}
			return filterName;
		},

	},
	data()
	{
		return {
			pickerStatus: false,
			sortInputActive: false,
			masks: {
				weekdays: "WW",
			},
			popupStatus: this.showMarkerPopup,
			map: false,
			zones: false,
			zonesObj: {},
			restaurantsPlacemarks: [],
			myPlacemark: false,
			mapAddress: "",
			coordinates: "",
			users: [],
			sort: "type",
			sortOrder: "increase",
		};
	},
	methods: {
		...mapActions("map", ["getDataUser"]), async getUserData(user, idx)
		{
			if (user.orders.length) return user.expandedContent.expanded = !user.expandedContent.expanded;

			const start = this.$store.state.map.dates.start;
			const end = this.$store.state.map.dates.end;
			const fd = new FormData();

			fd.append("post[dateFrom]", `${("" + start.getDate()).padStart(2, 0)}.${("" + (start.getMonth() + 1)).padStart(2, 0)}.${start.getFullYear()}`);
			fd.append("post[dateTo]", `${("" + end.getDate()).padStart(2, 0)}.${("" + (end.getMonth() + 1)).padStart(2, 0)}.${end.getFullYear()}`);
			fd.append("post[unique]", "");
			fd.append("post[lat]", this.$store.state.map.coords.lat);
			fd.append("post[lon]", this.$store.state.map.coords.lon);
			fd.append("post[userId]", user.id);

			await this.getDataUser({id: user.id, payload: fd});

			this.users[idx].orders = this.orderData.rows[idx].ordersList;
			user.expandedContent.expanded = !user.expandedContent.expanded;
		},
		async initMap()
		{
			const self = this;
			if (this.mapid === "restMap" && this.$store.state.currentCityLat && this.$store.state.currentCityLon)
			{
				self.coordinates = [this.$store.state.currentCityLat, this.$store.state.currentCityLon];
			} else
			{
				self.coordinates = this.deliveryAddress ? await this.setCoordinates() : this.getStartPointByCity();
			}

			ymaps.ready(async () =>
			{
				self.map = new ymaps.Map(self.mapid,

					{
						center: self.coordinates,

						// Если адрес доставки указан, зумим карту до дома
						zoom: this.deliveryAddress === "" ? this.getStartZoomByCity() : 18.5, controls: [],
					},

					{autoFitToViewport: "always", suppressMapOpenBlock: true, avoidFractionalZoom: false});

				// Сдвиг точки на карте
				if (this.deliveryAddress) self.map.panTo(await self.setCoordinates(), {
					delay: 0, duration: 0,
				});

				self.map.behaviors.disable('dblClickZoom');
				self.$emit("mapReady", self.map);
			});
		},

		checkYmap()
		{
			const needWait = typeof (ymaps) == "undefined" || typeof (ymaps.geocode) == "undefined" || this.currentCity === false;
			needWait ? setTimeout(this.checkYmap, 100) : this.initMap();
		},
		closePopup()
		{
			this.popupStatus = false;

			this.$emit("update:showMarkerPopup", false);
		},
		closePicker()
		{
			if (this.pickerStatus)
			{
				this.pickerStatus = false;
			}
		},
		async setCoordinates()
		{
			if (!this.deliveryAddress) return this.getStartPointByCity();

			const {lat, lan} = await this.getAddressCoorinates(this.deliveryAddress, true);

			return [lat, lan];
		},

		changeZoom(count)
		{
			if (!this.map) return;
			this.map.setZoom(this.map.getZoom() + count, {checkZoomRange: true});
		},

		getStartPointByCity()
		{
			const coords = this.$cookie.get("coords");

			if (coords && coords.lat && coords.lan) return [coords.lat, coords.lon];

			const currentSelectCity = this.cities.find(el => el.UF_NAME.trim() === this.currentCity.trim());

			return currentSelectCity ? [currentSelectCity.UF_LAT, currentSelectCity.UF_LON] : [55.740811, 37.652301];
		},

		getStartZoomByCity()
		{
			switch (this.currentCity)
			{

				case "Московская область":
					return 10;

				default:
					return 12.5;
			}
		},

		geolocateUser()
		{
			if (!navigator.geolocation || !this.map) return;
			navigator.geolocation.getCurrentPosition(// Успешное получение позиции
				({coords}) =>
				{
					this.map.panTo([coords.latitude, coords.longitude]);
				},

				// Ошибка при получения позиции
				() => console.error,

				// Параметры Geolocation API
				{
					enableHighAccuracy: true, timeout: 15000, maximumAge: 0,
				});
		},

		updateDates(dates)
		{
			//записать новые даты в стор
			this.$store.commit("map/setDates", dates);
			// отправить запрос на получения данных о заказах
			const start = this.$store.state.map.dates.start;
			const end = this.$store.state.map.dates.end;
			let fd = new FormData();
			fd.append("post[dateFrom]", `${("" + start.getDate()).padStart(2, 0)}.${("" + (start.getMonth() + 1)).padStart(2, 0)}.${start.getFullYear()}`);
			fd.append("post[dateTo]", `${("" + end.getDate()).padStart(2, 0)}.${("" + (end.getMonth() + 1)).padStart(2, 0)}.${end.getFullYear()}`);
			fd.append("post[unique]", "");
			fd.append("post[lat]", this.$store.state.map.coords.lat);
			fd.append("post[lon]", this.$store.state.map.coords.lon);
			this.$store.dispatch("map/getDataOrders", fd);
		},
		closeSort()
		{
			this.sortInputActive = false;
		},
	},

	watch: {
		showMarkerPopup(val)
		{
			this.popupStatus = val;
		},

		async needMapUpdate()
		{
			if (this.needMapUpdate && this.map)
			{
				const coordinates = await this.setCoordinates();

				if (coordinates)
				{
					this.map.panTo(coordinates);
					this.$store.commit("map/SET_MAP_UPDATE", false);
				}
			}
		},

		"$store.state.map.ordersData"()
		{
			this.users = this.$store.state.map.ordersData.rows.map(item => ({
				id: item.user.id,
				name: item.user.name,
				phone: item.user.phone,
				type: item.user.type,
				summ: item.orders.summ,
				count: item.orders.count,
				orders: item.ordersList,
				expandedContent: {
					expanded: false, date: "18.04.2022", time: "13:58", summ: "3000",
				},
			}));
		},
	},
	/**
	 * прыгает на второй стейт если пришли с основного поиска
	 */
	mounted()
	{
		this.$store.commit("map/SET_MAP_UPDATE", false);
		loadYmap({
			apiKey: process.env.VUE_APP_YA_API_KEY,
			lang: "ru_RU", coordorder: "latlong", version: "2.1",
		}).then(this.checkYmap);
		if (!!process.env.VUE_APP_MOBILE)
		{
			appHelper.windowSizeControl("popup-block", false);
		}
	},
};
</script>

<style
	lang="scss"
	scoped
>
.delivery-map
{
	position: relative;

	.ui-block-preloader
	{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.6);
	}
}

.data-picker__input
{
	position: relative;
	max-width: 270px;

	input
	{
		cursor: pointer;
		background: #F3F3F3;
		border-radius: 6px;
		padding: 8px 0;
		outline: none;
		border: none;
		width: 270px;
		height: 40px;
		padding-left: 49px;
		font-family: "GoogleSans-Regular", sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #3D4248;
	}
}

.restaurant__filters
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-bottom: 16px;
}

.row-value-label
{
	font-family: "GoogleSans-Regular", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #3D4248;
}

.row__head
{
	display: flex;
	justify-content: space-between;
	background: #F8F8F8;
	padding: 10px 0 10px 45px;
	box-shadow: inset 0px -1px 0px #E6E6E6;
}

.row__value
{
	background: #F8F8F8;
	padding: 10px 0 10px 45px;
	box-shadow: inset 0px -1px 0px #E6E6E6;
}

.row__date, .row__date-value
{
	flex: 50%;
}

.row__time, .row__time-value
{
	flex: 20%;
}

.row__summ, .row__summ-value
{
	flex: 30%;
}

.row__value
{
	display: flex;
	justify-content: space-between;
}

.data-picker__input::before
{
	content: url('~@/assets/img/dataPicker.svg');
	height: 20px;
	width: 20px;
	position: absolute;
	left: 14px;
	top: 9px;
	pointer-events: none;
}

.filters__sort
{
	position: relative;
}

.data-picker__input
{
	input:focus
	{
		outline: 1px solid #66BE6D;
		border-radius: 6px;
	}
}

.data-picker__input::after
{
	content: url('~@/assets/img/arrowDown.svg');
	height: 20px;
	width: 20px;
	position: absolute;
	right: 14px;
	top: 9px;
	pointer-events: none;
}

.restaurant__close-btn
{
	position: absolute;
	right: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 48px;
	width: 48px;
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15)
}

.user__type
{
	padding: 2px 4px;
	width: 60px;
	height: 20px;
	font-family: "GoogleSans-Regular", sans-serif;
	font-style: normal;
	text-align: center;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
	color: #3F6516;
	margin-right: 20px;
	border-radius: 4px;
}

.user__name
{
	display: flex;
	align-items: center;
	padding: 12px 0;
	flex: 50%;
	justify-content: space-between;
}

.user__phone
{
	flex: 20%;
	font-family: "GoogleSans-Medium", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.user__order-count
{
	flex: 20%;
}

.user__order-count
{
	font-family: "GoogleSans-Medium", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.name-label
{
	flex: 50%;
	margin-left: 45px;
}

.phone-label
{
	flex: 20%;
}

.order-count-label
{
	flex: 20%;
}

.row-name-label
{
	font-family: "GoogleSans-Regular", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: #979797;
}

.row-item
{
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding-left: 45px;
	box-shadow: inset 0px -1px 0px #E6E6E6;
}

.arrowUp::before
{
	content: url('~@/assets/img/arrowUp.svg');
	height: 24px;
	width: 24px;
}

.arrowDown
{
	content: url('~@/assets/img/arrowDown.svg');
	height: 24px;
	width: 24px;
}

.user__fio-label
{
	font-family: "GoogleSans-Medium", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	max-width: 250px;
}

.users-list
{
	background: #F8F8F8;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	box-shadow: inset 0px -1px 0px #E6E6E6;
}

.phys-user-type
{background: #E6FBD9;}

.juridical-user-type
{background: #E1F2FF;}

.restaurant__address
{
	margin-top: 10px;
}

.restaurant
{
	position: absolute;
	z-index: 3;
	background: #fff;
	padding: 32px;
	width: 55%;
	height: 100%;
	min-height: 100%;
	overflow-y: auto;
}

#restMap
{
	min-height: 640px;
}

.restaurant__zone
{
	font-family: "GoogleSans-Regular", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #979797;
}

.zone-label
{
	font-size: 14px;
	line-height: 24px;
	color: #40AE49;
}

.order-count__label
{
	font-family: "GoogleSans-Medium", sans-serif;
	font-size: 16px;
	line-height: 24px;
	color: #3D4248;

}

.sort-options
{
	position: absolute;
	right: 0;
	padding: 20px 30px 10px 40px;
	background: #FFFFFF;
	border: 1px solid #E5E5E7;
	box-shadow: 0 12px 60px 4px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
	max-width: 200px;
	margin-left: auto;

	hr
	{
		width: 192px;
		margin-left: -42px;
	}
}

.sort__label
{
	cursor: pointer;
	font-family: "GoogleSans-Regular", sans-serif;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
}

.sort-selected
{
	display: flex;
	flex-direction: row;
	margin-left: 5px;
}

.data-picker
{
	position: absolute;
	top: 210px;

	&::v-deep
	{
		.vc-weeks
		{
			gap: 4px 0;
		}

		.vc-highlight
		{
			//background: #40AE49 !important;
			//color: #fff !important;/
		}

		.vc-highlight-base-middle,
		.vc-highlight-base-start,
		.vc-highlight-base-end
		{
			background-color: #F0F8F0 !important;
		}
	}
}

.option-label__active::before
{
	content: url('~@/assets/img/confirm.svg');
	height: 20px;
	width: 20px;
	position: absolute;
	left: -28px;
}

.sort-selected
{
	color: #40AE49;
}

.option-label
{
	cursor: pointer;
	font-family: "GoogleSans-Regular", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #3D4248;
	margin-bottom: 16px;
	position: relative;
}

.restaurant__orders-count
{
	margin: 15px 0;
}

.order-count__value
{
	color: #40AE49;
}

.address-label
{
	font-family: "GoogleSans-Bold", sans-serif;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	color: #3D4248;
}

.filters__data-picker
{
	&::v-deep(.vc-weekday)
	{
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		color: #40AE49;
		text-transform: capitalize;
	}

	&::v-deep(.vc-header)
	{
		padding: 25px 18px 10px 18px;
	}

	&::v-deep(.vc-highlight)
	{
		width: 50px;
		height: 50px;
	}

	&::v-deep(.vc-container)
	{
		box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
	}

	&::v-deep(.vc-day-content)
	{
		height: 50px;
		width: 50px;
	}

	&::v-deep(.vc-pane-layout)
	{
		padding: 10px;
	}

	&::v-deep(.vc-arrows-container)
	{
		padding: 20px 30px;
	}

	&::v-deep(.vc-title)
	{
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		text-transform: capitalize;
		color: #3D4248;
	}
}

.map
{
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 10px;
	overflow: hidden;

	&__address
	{
		display: none;
	}

	&__icon
	{
		position: absolute;
		height: 77px;
		width: 48px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -100%);
		background: url("~@/assets/img/mapMark.svg") no-repeat;
		background-size: cover;
		z-index: 2;
		pointer-events: none;

		& &-text
		{
			display: none;
		}

		&-disabled
		{
			padding: 12px 24px;
			display: flex;
			height: 44px;
			justify-content: center;
			align-content: center;
			background: $textColor;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -100%);
			z-index: 2;
			border-radius: 10px;
			pointer-events: none;

			& .map__icon-text
			{
				display: block;
				font-size: 14px;
				color: #fff;
				line-height: 20px;
				font-weight: 500;
				white-space: nowrap;
			}

			&:after
			{
				content: '';
				background: $textColor;
				height: 14px;
				width: 14px;
				bottom: -4px;
				position: absolute;
				left: 50%;
				transform: translateX(-50%) rotate(45deg);
			}
		}
	}

	&__controls
	{
		position: absolute;
		touch-action: manipulation;
		display: flex;
		height: 230px;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		z-index: 2;
		right: 15px;
		top: 96px;

		&-geolocation
		{
			width: 40px;
			height: 40px;
			padding: 11px 10px 9px 10px;
			background: #fff;
			border: 1px solid $greyBorder;
			border-radius: 6px;
			margin-top: 150px;
			box-shadow: 0 20px 40px rgba(82, 82, 82, 0.2);
		}

		&-zoom
		{
			border: 1px solid $greyBorder;
			border-radius: 6px;
			background: #fff;
			box-shadow: 0 20px 40px rgba(82, 82, 82, 0.2);

			&-in
			{
				background: #fff;
				border: none;
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
			}

			&-out
			{
				background: #fff;
				border: none;
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
			}
		}
	}
}

@media (max-width: 1410px)
{
	.restaurant
	{
		&::v-deep
		{
			.vc-pane-layout
			{grid-template-columns: repeat(1, 1fr) !important;}
		}
	}
}

@media (max-width: 1090px)
{
	.restaurant
	{
		width: 100%;
		padding: 15px;

		.restaurant__filters
		{
			flex-direction: column;
			gap: 10px 0;
		}
	}
}

@media (max-width: 567px)
{
	.delivery-map
	{
		position: relative;

		.ui-block-preloader
		{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			height: 100%;
		}
	}

	.map
	{
		&__address
		{
			display: flex;
			justify-content: center;
			position: relative;
			top: 24px;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: $textColor;
			text-align: center;
			z-index: 1;
			font-family: $mainFontNew;

			&-wrapper
			{
				width: 100%;
				position: absolute;
			}

			&-title
			{
				font-size: 12px;
				line-height: 14px;
				color: #979797;
			}

			&-text
			{
				font-family: $mainFontBoldNew;
				font-weight: bold;
				font-size: 15px;
				line-height: 20px;
				background: rgba(255, 255, 255, 0.75);
				padding: 6px 12px;
				border-radius: 8px;
			}
		}

		&__controls
		{
			top: auto;
			bottom: 55px;
			right: 20px;

			&-geolocation
			{
				margin-top: 55px;
			}
		}
	}
}

@media (max-width: 374px)
{
	.map
	{
		&__address
		{
			max-width: 272px;
			width: fit-content;
			top: 8px;
			border-radius: 8px;
			padding: 4px 12px;

			&-title
			{
				display: none;
			}

			&-text
			{
				font-size: 12px;
				line-height: 20px;
				color: #3D4248;
				box-shadow: 0 2px 16px rgba(0, 0, 0, 0.16);
			}
		}

		&__icon
		{
			&-disabled
			{
				padding: 6px 12px;
				height: 28px;

				& .map__icon-text
				{
					font-size: 12px;
					line-height: 16px;
				}
			}
		}

		&__controls
		{
			top: unset;
			bottom: 12px;
			height: auto;
			right: 20px;


			&-geolocation
			{
				margin-top: 55px;
			}
		}
	}
}
</style>
